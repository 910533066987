module.exports = {
  general_error_msg        : 'Error',
  general_msg_ok           : 'Success',
  
  login_error_alert_title  : 'Error',
  
  login_reset_pw_title     : 'Reset password',
  login_reset_pw_submit    : 'Change password',
  login_register_title     : 'Register',
  login_login_title        : 'Login',
  login_login2FA_title     : 'Login 2FA',
  login_login_input_name   : 'Name',
  login_login_input_email  : 'Email',
  login_login_input_pw     : 'Password',
  login_login_input_pwrepeat    : 'Password repeat',
  login_login_input_2fa         : 'Google 2FA code',
  login_button_login            : 'Login',
  login_button_register         : 'Register',
  login_button_pwreset          : 'Submit',
  login_button_submit           : 'Submit',
  login_check_remember_me       : 'Remember me',
  login_check_accept_tc         : 'Accept terms and conditions',
  login_goto_pwreset            : 'forgot password?',
  login_goto_login              : 'Back to login',
  login_goto_register           : 'Register',
  login_passwordval_minchars    : 'At least 8 characters',
  login_passwordval_maxchars    : 'Max 64 characters',
  login_passwordval_number      : 'At least 1 number',
  login_passwordval_lowercase   : 'At least 1 lower character',
  login_passwordval_uppercase   : 'At least 1 uppercase character',
  login_passwordval_special     : 'At least 1 character ( !@#$%^&*)(+=._- )',
  login_passwordval_match       : 'Paswords need to match',
  login_input_error_email       : 'Invalid email given',
  login_input_error_password    : 'Invalid password given',
  login_login_input_invitecode  : 'Invite code',
  
  register_input_error_password_rules   : 'Invalid password given',
  register_input_error_tc               : 'Terms and conditions need to be accepted',
  register_success_title        : 'Success',
  register_success_msg          : 'Your account has been created successfully',
  login_input_error_2fa         : 'Invalid 2FA code given',
  
  access_token_invalid_noaccess  : 'Invalid credentials',
  
  password_reset_title          : 'Password reset',
  password_reset_subtitle       : 'Please choose a new password and press submit',
  password_reset_password       : 'New password',
  password_reset_password_rep   : 'Repeat password',
  password_reset_invalid_password   : 'Invalid password given',
  password_reset_success_title   : 'Success',
  
  email_verify_successfull      : 'Success',
  email_verify_success_title    : 'Email verify successfull',
  
  setup_2fa_add             : 'Add 2Fa',
  setup_2fa_confirm         : 'Confirm 2fa',
  setup_2fa_confirm_btn     : 'Confirm 2FA',
  setup_2fa_code_input      : '2FA code',
  setup_2fa_remove          : 'Remove 2Fa',
  setup_2fa_remove_btn      : 'Remove 2Fa',
  
  contact_us_title          : 'Contact us',
  contact_us_name           : 'Name',
  contact_us_email          : 'Email',
  contact_us_message        : 'Message',
  contact_us_submit         : 'Submit your question',
  contact_us_error_name     : 'Your name is missing',
  contact_us_error_email    : 'Your email is missing',
  contact_us_error_msg      : 'A message is missing',
  contact_us_msg_received   : 'Your message is received. We will try to contact you soon asap.',
  
  setup_notif_title         : 'Notifications',
  setup_notif_enable        : 'Enable Notifications',  
  setup_notif_disable       : 'Disable Notifications',
  setup_notif_test          : 'Test Notifications',
  setup_notif_testmsg       : 'Notifications will be sent in 30 seconds',
  
  post_title                : 'Create a post',
  post_title_input_message  : 'Message',
  post_title_input_url      : 'Url',
  post_title_btn_submit     : 'Post',
  
  post_tweetprofile_select  : 'Select Tweet Profile'
  }
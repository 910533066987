const DefaultDialog = { 
    DialogType    : 'alert', //'alert/alertError/confirm/...'
    title         : '', 
    type          : '', 
    message       : '', 
    confirmText   : '', 
    onConfirm     : () => { }, 
    cancelText    : '',
    onCancel      : () => { },    
}
  
const state = {
  dialogPayload : null,  
};

const getters = {
  getDialogState: state => state.dialogPayload,
}

const actions = { };


const mutations = {
  // eslint-disable-next-line no-unused-vars
  setDialog(state, Dialog){
    // console.info("setDialog:", Dialog);
    state.dialogPayload = {...DefaultDialog, ...Dialog };
  }
};



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
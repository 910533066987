<template>
  <section class="wa-full-height">

    <div class="columns is-centered is-gapless is-marginless">
      <div class="column is-mobile is-narrow mt2rem mb2rem">
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title"> Welkom bij {{ projectTitle }} - User</div>
        
        <div class="column">
          <b-button class="button" @click="getTestData">Test??</b-button>
        </div>
        
        <div class="column">
          <b-button class="button" @click="getTestFeed">getFeed</b-button>
        </div>
        
        <div class="column">
          <b-button class="button" @click="requestNotifyToken">requestNotifyToken</b-button>
        </div>
        
        <div class="column">
          <b-button class="button" @click="DeleteNotifyToken">deleteNotifyToken</b-button>
          <div>
            <br>          
            {{ pushtoken }}
          </div>
        
        </div>
        
        
        
        <div class="ml1rem pb0 mb1rem">
          <ul id="password-constraints" class="ml0rem" v-for="(tvalue, tkey) in token" v-bind:key="tkey">
            <li style="font-size:0.9rem;"><strong>{{tkey }}</strong>: {{ tvalue }}</li>
          </ul>
        </div>
        
        
        
        <div class="ml1rem pb0 mb1rem">
          <ul id="password-constraints" class="ml0rem" v-for="(dbvalue, dbkey) in dbuser" v-bind:key="dbkey">
            <li style="font-size:0.9rem;">
              <strong>{{dbkey }}</strong>: 
              <div>{{dbvalue}}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import { isSupported, getToken, deleteToken } from "firebase/messaging";
       
export default {
  name: "UserPage",
  data() {
    return {
      projectTitle : process.env.VUE_APP_TITLE,
      token   : [],
      dbuser : [],
      pushtoken: ''
    }
  },
  
  mounted(){
    this.getTestJWTData();
    //Auth.signInAnonymously().catch((err) => {console.info("Firebase login error:", err)}); //FB Anonymous-Login
    // messaging.onTokenRefresh = this.onNotifyTokenRefresh;
    // onTokenRefresh(this.$messaging, this.onNotifyTokenRefresh);
    
  },
    
  methods: {
    
    async requestNotifyToken() {
      if (!await isSupported().catch(() => false)) {
        console.info(`no notification browser suppport...`);
        return false;
      }
      
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          await this.onNotifyTokenRefresh();
        }
      } catch (err) { 
        console.info('Unable to get permission to notify.', err); 
      }
    },
    
    async onNotifyTokenRefresh() {
      console.info(`onNotifyTokenRefresh...:`)
      try {
        const token = await getToken(this.$messaging);
        this.pushtoken = token;
        console.info('Notification permission granted.', token);
      } catch (err) { 
        console.info('Unable to retrieve refreshed token ', err)
      }      
    },
    
    async DeleteNotifyToken(){       
      console.info(`DeleteNotifyToken`);
      try {
        await deleteToken(this.$messaging);
        console.info('Notification token deleted');
      } catch (err) { 
        console.info('Unable to delete notify token', err)
      }
    },
    
    
    async getTestData(){
      for(let i = 0;i< 10;i++) {
        let testdata = this.$store.dispatch('test/getTestData', null);   
        console.info("testdata2:", testdata);
      }
    },    
    
    async getTestFeed(){
      let testdata = await this.$store.dispatch('test/getTestJWTData', null);   
      console.info("testdata1:", testdata);    
    },
    
    async getTestJWTData(){
      let testjwtdata = await this.$store.dispatch('test/getTestJWTData', null);   
      // console.info("testjwtdata:", testjwtdata);
      this.token =  testjwtdata.token;
      // console.info("this.token:", this.token);
      this.dbuser =  testjwtdata.dbuser;
      // console.info("this.dbuser:", this.dbuser);
    },
  }
  
};
</script>

<style scoped>
</style>
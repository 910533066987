
const state = {
  loader    : false,
  Timeout : null,
};
const getters = {
  getLoaderState: state => state.loader,
}

const actions = {
  
  showLoader({ dispatch }, duration) {    
    if (duration !== null) {
      if (state.Timeout !== null) {
        clearTimeout(state.Timeout);
      }
      
      state.Timeout = setTimeout(() => {
        dispatch("hideLoader", 0);
      }, duration);      
    }
    
    state.loader = true;
  },
  
  // eslint-disable-next-line no-unused-vars
  hideLoader({ dispatch }, flag) {
    if (state.Timeout !== null) {
      clearTimeout(state.Timeout);
    }
    state.loader = false;
    state.Timeout = null;
  },
};


const mutations = {};



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
import axios_auth from '@/plugins/axios_auth';

// import api_lang from '@/lang/api_language'
import web_lang from '@/lang/web_language'

import {
  NOTIFICATIONS_REGISTER,
  NOTIFICATIONS_DELETE,
  NOTIFICATIONS_TEST,
} from '@/config/api-url-general';

import { messaging } from '@/plugins/firebase/messaging';
import { isSupported, getToken, deleteToken } from "firebase/messaging";
        

const state = {
  notificationsState : {
    hasPermission : null, // granted/denied/default
    lastChanged : null,
    token : null,
  }
};


const getters = {
  getNotificationsState: state => {
    return state.notificationsState;
  },
};


const actions = {
  
  //1. update browser Permission status
  async updatePermission ({ commit }) {    
    try {
      if (!await isSupported().catch(() => false)) {
        console.info(`no notification browser suppport...`);
        return false;
      }
      
      commit('setPermission', Notification.permission);
      return Notification.permission;
            
    } catch (err) {
      console.info("Error notifications updatePermission:", err);     
      commit('setPermission', 'default');
      return false;
    }
  },
  
  
  
  //2. request notification Token ?
  async requestToken ({ commit, dispatch }, { deviceID, platform } ) {    
    try {
      const hasPermission = await dispatch('updatePermission');
      if (hasPermission === 'denied' || hasPermission === false) {
        throw "no permission given";
      }
      
      await Notification.requestPermission();    
      await dispatch('updatePermission');  
      const token = await getToken(messaging);
      
      await axios_auth.post(NOTIFICATIONS_REGISTER, {
        token,
        devid: deviceID,
        platform
      });
      
      commit('setToken', token);
      return token;
      
    } catch (err) {
      console.info("Error notifications requestToken:", err);     
      commit('setToken', null);
      return false;
    }
  },
  
    
  //3. delete notification token
  async removeToken ({ commit, dispatch }, { deviceID, platform }) {    
    try {
      const hasPermission = await dispatch('updatePermission');
      if (hasPermission === 'denied' || hasPermission === false) {
        throw "no permission given";
      }
      
      await deleteToken(messaging);
      
      await axios_auth.post(NOTIFICATIONS_DELETE, {
        devid: deviceID,
        platform
      });
      commit('setToken', null);
      return true;
      
    } catch (err) {
      console.info("Error notifications deleteToken:", err);     
      commit('setToken', null);
      return false;
    }
  },
  
  //3. test all notification tokens
  async test ({ commit }) {    
    try {
      await axios_auth.post(NOTIFICATIONS_TEST);
      
      commit("dialog/setDialog",  { DialogType: 'alert', 
                                    title: web_lang('setup_notif_testmsg'),
                                    message: web_lang('setup_notif_testmsg'),
                                  },
                                  { root: true }
      );
      return true;
      
    } catch (err) {
      console.info("Error notifications test:", err);
      return false;
    }
  },
  
  
};




const mutations = {
  setPermission: (state, newPermission) => {
    console.info(`notifications setPermission:`, newPermission);
    state.notificationsState = {
      ...state.notificationsState, 
      ...{
        hasPermission : newPermission,
        lastChanged : new Date().getTime(),
      }
    }
  },
  
  setToken: (state, newToken) => {
    console.info(`setToken:`, newToken);
    state.notificationsState = {
      ...state.notificationsState, 
      ...{
        token : newToken,
        lastChanged: new Date().getTime()
      }
    }
  },
  
};



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
import store from '@/store';
import web_lang from '@/lang/web_language'

// import { waitTime } from '@/helpers/functions';
import Storage from '@/helpers/storage';
import { COOKIE_REFRESH_INPROGRESS } from '@/config/app.js';

import axios from '@/plugins/axios_main';

import {      
    BAD_REQUEST,              // 400
    FORBIDDEN,                // 401
    UNAUTHENTICATED,          // 403
    VALIDATION_SERVER_ERROR,  // 422
    LOGIN_2FA_REQUIRED,       // 497
    ACCESS_TOKEN_EXPIRED,     // 497
    REFRESH_TOKEN_EXPIRED,    // 498
    INTERNAL_SERVER_ERROR,    // 500
} from '@/config/status-codes';

const ResponseErrorHandler = async (error) => {
    if (error.response) {
      if (error.response.status !== LOGIN_2FA_REQUIRED && 
          error.response.status !== ACCESS_TOKEN_EXPIRED &&
          error.response.status !== REFRESH_TOKEN_EXPIRED) {
        console.info("Axios ResponseErrorHandler Error:", error.response);  
      }
      
      // Process Api response error
      switch(error.response.status) {
        case BAD_REQUEST:
          return await Process_BAD_REQUEST400(error);
        case UNAUTHENTICATED:
          return await Process_UNAUTHENTICATED401(error);
        case FORBIDDEN:
          return await Process_FORBIDDEN403(error);
        case VALIDATION_SERVER_ERROR:
          return await Process_VALIDATION_SERVER_ERROR422(error);
        case ACCESS_TOKEN_EXPIRED:
          return await Process_ACCESS_TOKEN_EXPIRED497(error);
        case REFRESH_TOKEN_EXPIRED:
          return await Process_REFRESH_TOKEN_EXPIRED498(error);
        case INTERNAL_SERVER_ERROR:
          return await Process_INTERNAL_SERVER_ERROR500(error);
          
        default:
          //return await Process_VALIDATION_SERVER_UNKNOWN(error);
      }      
    }
    
    return Promise.resolve(error.response);
};


const Process_BAD_REQUEST400 = async (error) => {
  console.info("Respose is BAD_REQUEST...", error.response.status);
  
  store.commit("dialog/setDialog", 
        { DialogType: 'alertError', 
          title: web_lang('login_error_alert_title'), 
          message: 'error.. BAD_REQUEST400', 
        }
      );
  
  return Promise.reject(error);
}


const Process_UNAUTHENTICATED401 = async (error) => {
  console.info("Respose is UNAUTHENTICATED...", error.response.status );
  
  await store.dispatch('authentication/logout');
  
  const msg = getErrorCode(error.response);
  store.commit("dialog/setDialog", 
        { DialogType: 'alertError', 
          title: web_lang('login_error_alert_title'), 
          message: msg,
        }
      );
  
  return Promise.reject(error);
}


const Process_FORBIDDEN403 = async (error) => {
  console.info("Respose is FORBIDDEN...", error.response.status );
  
  await store.dispatch('authentication/logout');
  
  const msg = getErrorCode(error.response);
  if (msg !== '') {
    store.commit("dialog/setDialog", 
        { DialogType: 'alert', 
          title: web_lang('login_error_alert_title'), 
          message: msg,
        }
      );
  }  
      
  return Promise.reject(error);
}


const Process_VALIDATION_SERVER_ERROR422 = async (error) => {
  // console.info("Respose is VALIDATION_SERVER_ERROR...", error.response.status );
  const msg = getErrorCode(error.response);
  if (msg !== '') {
    store.commit("dialog/setDialog", 
        { DialogType: 'alert', 
          title: web_lang('login_error_alert_title'), 
          message: msg,
        }
      );
  }  
  return Promise.reject(error);
}


const Process_ACCESS_TOKEN_EXPIRED497 = async (error) => {
  // console.info("Respose is ACCESS_TOKEN_EXPIRED...", error.response.status );
  
  const refreshTokenIsUpdated = await UpdateRefreshToken();
  
  if (refreshTokenIsUpdated) {
    // console.info("refreshTokenIsUpdated:", refreshTokenIsUpdated);    
    return axios(error.config);
  }
  
  return Promise.reject(error);
}







const Process_REFRESH_TOKEN_EXPIRED498 = async (error) => {
  console.info("Respose is REFRESH_TOKEN_EXPIRED...", error.response.status );
  
  await store.dispatch('authentication/logout', null, { root: true });
  
  store.commit("dialog/setDialog", 
        { DialogType: 'alertError', 
          title: web_lang('login_error_alert_title'), 
          message: 'error.. REFRESH_TOKEN_EXPIRED',
        }
      );
  return Promise.reject(error);
}


const Process_INTERNAL_SERVER_ERROR500 = async (error) => {
  console.info("Respose is INTERNAL_SERVER_ERROR...", error.response.status );
  
  store.commit("dialog/setDialog", 
        { DialogType: 'alertError', 
          title: web_lang('login_error_alert_title'), 
          message: 'error.. INTERNAL_SERVER_ERROR500',
        }
      );
  return Promise.reject(error);
}


// const Process_VALIDATION_SERVER_UNKNOWN = async (error) => {
//   console.info("Respose is VALIDATION_SERVER_UNKNOWN...", error.response.status );
  
//   store.commit("dialog/setDialog", 
//         { DialogType: 'alertError', 
//           title: web_lang('login_error_alert_title'), 
//           message: 'error.. INTERNAL_SERVER_ERROR500',
//           onConfirm :() => { console.info("Done!"); }}, 
//         { root: true }
//       );
//   return Promise.reject(error);
// }








const getErrorCode = (response) => {
  if (!response || !response.data || !response.data.msg || !response.data.msg.c) {
    return web_lang('general_error_msg');
  } 
  
  return response.data.msg.m
}

let refreshRtn;
const UpdateRefreshToken = async () => {
  let refreshInProgress = Storage.ReadObj(COOKIE_REFRESH_INPROGRESS);
  try {
    if (refreshInProgress !== true) {
      Storage.StoreObj(COOKIE_REFRESH_INPROGRESS, true);      
      refreshRtn = store.dispatch('authentication/refreshToken', null, { root: true });
    }
    
    await refreshRtn;
    Storage.StoreObj(COOKIE_REFRESH_INPROGRESS, false);
    return refreshRtn;
      
  } catch (err) {
    Storage.StoreObj(COOKIE_REFRESH_INPROGRESS, false);
    return false;
  }
}



export { ResponseErrorHandler };
import axios_auth from '@/plugins/axios_auth';
// import router from "@/router/router";

// import api_lang from '@/lang/api_language'
// import web_lang from '@/lang/web_language'

import {
  CONTACT_US,
} from '@/config/api-url-general';
        

const state = {
  
};

const getters = {
 
}

const actions = {
  
  async sendMessage ({ dispatch }, data) {    
    try {
      dispatch("loader/showLoader", 15000, { root: true });
      
      let contactus = await axios_auth.post(CONTACT_US, data);
      // console.info("contactus:", contactus);
      
      dispatch("loader/hideLoader", 0, { root: true });
      return contactus.data.payload;
      
    } catch (err) {
      console.info("Error contactus sendMessage:", err);      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  
};



const mutations = {
 
};



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
<template>
    <section>
              
      <b-loading :is-full-page="isFullPage" :active.sync="getLoaderState" :can-cancel="false"></b-loading>
      
    </section>
</template>

<script>

export default {
    name: "comp_loader",
    
    props: {},
    
    computed: {
      getLoaderState() {
        return this.$store.getters['loader/getLoaderState'];
      },
    },
    
    data() {
      return {
        isFullPage : true,
      }
    },
    
    watch: {},
    
    mounted(){},
    
    methods: {      
      
    }
};
</script>

<style scoped>


    
</style>

<template>
  <section>
    <h1>Grid example: <a href='https://www.youtube.com/watch?v=9zBsdzdE4sM' target='_blank'>Youtube</a></h1>
    
    <div class="grid-container">
      <div class="grid-item grid-item-1">
        Item 1
        <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi fugiat debitis vero assumenda nobis inventore recusandae officia deserunt quia modi dolore exercitationem magnam enim, alias dignissimos consectetur autem cumque. Delectus consectetur dolorum, natus hic repellat dolorem esse commodi nihil iste dignissimos vero blanditiis sapiente inventore, repudiandae alias nesciunt consequatur voluptatem! -->
      </div>
      <div class="grid-item grid-item-2">
        Item 2
        <!-- Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nostrum, veniam. Error, eaque? Nesciunt est distinctio impedit, architecto consequatur ullam ipsum nisi nostrum excepturi necessitatibus? Dolores? -->
      </div>
      <div class="grid-item grid-item-3">Item 3</div>
      <div class="grid-item grid-item-4">Item 4</div>
      <div class="grid-item grid-item-5">Item 5</div>
      <div class="grid-item grid-item-6">Item 6</div>
    </div>
  </section>
</template>

<script>

export default {
  name: "gridExample",  
};
</script>


<style scoped>
  .grid-container{
    /* background-color: red; */
        
    display: grid;
    grid-template-columns: 200px 300px;
    /* grid-template-columns: 2fr 1fr; /* fr => fraction */
    /* grid-template-columns: repeat(2, 150px); */
    
    /* grid-auto-rows: 150px;     */
    /* grid-template-rows: 200px; */
    grid-auto-rows: minmax(150px, auto);    
    
    /* grid-row-gap: 20px; */
    /* grid-column-gap: 10px; */    
    grid-gap: 20px;
    
    /* https://www.youtube.com/watch?v=zqrmL6HEZtg */
    /* row-gap: 1rem; */
    /* column-gap: 1rem; */
    /* gap: 10rem; */
    
    /*grid-template-areas: 
      "header header"
      "sidebar content"
      "sidebar content"
    ;*/
    
    /* justify-content: start; */
    /* justify-content: end; */
    /* justify-content: stretch; */
    /* justify-content: space-around; */
    /* justify-content: space-evenly; */
    justify-content: center;
    
    /* align-content: center; */
    align-content: stretch;
    /* align-content: space-around; */
    height: 100vh;
    
    /* Items */
    /* justify-items: center; */
    justify-items: stretch;
    /* align-items: center; */
    /* align-items: start; */
    /* align-items: end; */
    align-items: stretch;
    
    
  }
  
  .grid-item{
    position: relative;
    padding: 20px;
    padding-top: 50px;
    background-color: #379AD6;
    color: #222;
    border: 1px solid white;
  }
  
  
  .grid-item-1{
    /* grid-area: header; */
    /* grid-column-start: 1; */
    /* grid-column-end: 3; */
    /* grid-column-end: -1; */
    /* grid-column: 1 / -1; */
    /* grid-column: span 2; */
    
    align-self: start;
    justify-self: center;
  }
  
  .grid-item-2{
    /* grid-area: sidebar; */
    /* grid-row-start: 2; */
    /* grid-row-end: 4; */
    /* grid-row: span 2; */
    
    align-self: end;
    justify-self: right;
  }
  .grid-item-3{
    /* grid-area: content; */
    /* grid-row-start: 2; */
    /* grid-row-end: 4; */
    /* grid-row: span 3; */
    
    align-self:stretch ;
    justify-self: right;
  }
  
  
  
  
  
  
  
  
  
  
  
  .grid-item:nth-child(odd) {
    background-color: #9bbdfa;
  }
  .grid-item::before {
    /* content: 'Grid Item'; */
    position: absolute;
    font-size: 25px;
    font-weight: bold;
    top: 10px;
    left: 15px;
  }
  
  .grid-item::after {
    position: absolute;
    font-size: 25px;
    top: 10px;
    right: 15px;
    font-weight: bold;
    
    /* height: */
  }
  
  
  
  /*
    em ->  based font size of that element
    rem -> based on the root font size (16px default)
    vh  (viewport height) -> viewport height 1vh => 1% of the current viewport height
    vw  (viewport width)  -> viewport width 1vh => 1% of the current viewport width
    vmax (viewport minimum) -> uses the largest of the viewport width or height (landscape/portrait)
    vmin (viewport maximum) -> uses the smallest of the viewport width or height (landscape/portrait)
  */

</style>
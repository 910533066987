<template>
  <section>
    <h1>FlexBox example: <a href='https://www.youtube.com/watch?v=fYq5PXgSsbE' target='_blank'>Youtube</a></h1>
    
    <div class="flexbox-container">
      <div class="flexbox-item flexbox-item-1">
        Item 1
        <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi fugiat debitis vero assumenda nobis inventore recusandae officia deserunt quia modi dolore exercitationem magnam enim, alias dignissimos consectetur autem cumque. Delectus consectetur dolorum, natus hic repellat dolorem esse commodi nihil iste dignissimos vero blanditiis sapiente inventore, repudiandae alias nesciunt consequatur voluptatem! -->
      </div>
      <div class="flexbox-item flexbox-item-2">
        Item 2
        <!-- Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nostrum, veniam. Error, eaque? Nesciunt est distinctio impedit, architecto consequatur ullam ipsum nisi nostrum excepturi necessitatibus? Dolores? -->
      </div>
      <div class="flexbox-item flexbox-item-3">Item 3</div>
      <div class="flexbox-item flexbox-item-4">Item 4</div>
      <div class="flexbox-item flexbox-item-5">Item 5</div>
      <div class="flexbox-item flexbox-item-6">Item 6</div>
      
      <!-- <div class="axis main-axis"></div> -->
      <!-- <div class="axis cross-axis"></div> -->
    </div>
  </section>
</template>

<script>

export default {
  name: "flexBoxExample",  
};
</script>


<style scoped>
  .flexbox-container{
    background-color: yellow;
    /* height: 700px; */
        
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    /* flex-direction: row; */
    
    /* justify-content: flex-start; */
    /* justify-content: center; */
    /* justify-content:space-between; */
    /* justify-content:space-around; */
    
    /* align-items: stretch; */
    /* align-items: flex-start; */
    /* align-items: flex-end; */
    /* align-items: center; */
    
    /* flex-wrap: wrap; */
    /* align-content: center; */
    /* align-content: flex-start; */
    /* align-content: flex-end; */
    /* align-content: space-between; */
    
    gap: 10rem;
    
  }
  
  .flexbox-item{
    width: 200px;
    margin: 10px;
    border: 3px solid #333;
    background-color: #dfdfdf;
    
    
  }
  
  .flexbox-item-1{
    /* flex: 1 0 0px;*/ /* grow shring bases*/
    /* flex: 1; */ /* grow*/
    
    min-height: 100px;
    flex-shrink: 0;
    
    align-self: center;
    align-self: flex-end;    
    
    /* order: 3; */
  }
  
  .flexbox-item-2{
    min-height: 200px;
    /* flex-grow: 1; */
    flex-grow: 2;
    
    flex-basis: 0; /* optional, start adding without item with*/
    
    align-self: center;
    /* align-self: stretch; */
    /* align-self: stretch; */
    
    /* order: 1; */
  }
  
  .flexbox-item-3{
    min-height: 300px;
    flex-grow: 1;
    
    flex-basis: 0; /* optional, start adding without item with*/
        
    align-self: center;
    align-self: flex-start;
    
    /* order: 2; */
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  .axis {
      position: absolute;
  }

  .cross-axis {
      left: 50%;
      transform: translateX(-50%);
      width: 5px;
      height: 100%;
      background-color: blue;
  }

  .main-axis {
      top: 50%;
      transform: translateY(-50%);
      height: 5px;
      width: 100%;
      background-color: red;
  }

  .axis::after {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-size: 2em;
  }

  .main-axis::after {
      position: absolute;
      content: 'Main Axis';
      color: red;
  }

  .cross-axis::after {
      white-space: nowrap;
      content: 'Cross Axis';
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      color: blue;
  }

</style>
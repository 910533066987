<template>
  <section class="wa-full-height">

    
    <!-- <Footer></Footer> -->
  </section>
</template>

<script>

export default {
  name: "verifyemailaddress",
  
  data() {
    return {
      isLoaded : true,
      frmdata: {
        uid         : null,
        emailtoken  : null,
      },
    }
  },
  
  mounted(){
    this.frmdata.uid        = this.$route.query.uid;
    this.frmdata.emailtoken = this.$route.query.v;
    // console.info("ValidateEmailToken..");
    this.ValidateEmailToken();
  },
  
  methods: {
    async ValidateEmailToken(){
      if (!this.frmdata.uid || !this.frmdata.emailtoken) return;
      
      let validateRtn = await this.$store.dispatch('authentication/validateEmailToken', this.frmdata);
      // console.info("validateRtn:", validateRtn);
      
      if (validateRtn !== false) {
        const ThaThis = this;
        this.$store.commit("dialog/setDialog", 
            { DialogType: 'alert', 
              title: this.$wl('email_verify_success_title'), 
              message: validateRtn.m,
              onConfirm :() => { 
                ThaThis.$router.push({name: 'userpage'}).catch();
              }
            },            
        ); 
      } else {
        this.$router.push({name: 'login'}).catch();
      }
    },
    
  }
  
};
</script>

<style scoped>
</style>
<template>
  <section class="wa-full-height">

    <div class="columns is-centered is-gapless is-marginless mt2rem mb1rem is-centered">
      <div class="column is-6-desktop is-8-tablet is-12-mobile">
         
                
      </div>
    </div>
    
  </section>
</template>

<script>


import { mapState } from 'vuex';

export default {
  name: "Home",
  
  components: {
    // Post,
  },
    
  data() {
    return {}
  },
  
  computed: {
    ...mapState('firebase', ['firebase/auth','auth']),
  },
  watch: {
    auth(newAuthState) {
      // console.info("Firebase getAuthState Changed:",  newAuthState);
      this.FBuid = newAuthState.uid
    },
  },
  
  created(){ },
  
  mounted(){
    console.info("mounted home:...");
    // this.$store.dispatch("loader/showLoader", 1500, { root: true });
  },
  
  
  methods: {
    
  }
};
</script>

<style lang="scss" scoped>

</style>
/* ACCOUNT / AUTHENTICAITON SERVER */
export const LOGIN_URL = '/auth/login';
export const LOGOUT_URL = '/auth/logout';

export const REGISTER_URL = '/auth/register';
export const VERIFY_EMAIL_URL = '/auth/email/verify';
export const REFRESH_TOKEN_URL = '/auth/refresh-token';

export const FORGOT_PASSWORD_URL = '/auth/password-reset';
export const RESET_PASSWORD_CHECK_URL = '/auth/password-set';
export const RESET_PASSWORD_CHANGE_URL = '/auth/password-set';

/* 2FA */
export const TWOFA_GET_STATUS   = '/account/2fa/status';
export const TWOFA_SETUP        = '/account/2fa/setup';
export const TWOFA_VERIFYSETUP  = '/account/2fa/verifysetup';
export const TWOFA_REMOVE       = '/account/2fa/remove';

/* Push notifications */
export const NOTIFICATIONS_REGISTER = '/account/notifications/set';
export const NOTIFICATIONS_DELETE   = '/account/notifications/delete';
export const NOTIFICATIONS_TEST     = '/account/notifications/test';

export const CONTACT_US         = '/default/contact';




<template>
    <div class="columns is-vcentered is-gapless is-centered is-mobile">
        <div class="column is-12 bcFFFFFF wa-card">
            <div v-if="cardIcon || cardTitle" class="columns is-gapless is-marginless is-centered is-mobile pt1rem">
                <div v-if="cardIcon" class="column is-mobile is-narrow mr1rem">
                    <b-icon
                        :icon="cardIcon"
                        size="is-large"
                        type="is-info">
                    </b-icon>
                </div>
                <div v-if="cardTitle" class="column is-mobile is-narrow">
                    <div class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light" >{{ cardTitle }}</div>
                </div>
            </div>
            <!-- <div v-if="hasCardTitle">
                <Title :title="cardTitle" :isCentered="true" :icon="cardIcon"></Title>
            </div> -->
            <div v-if="hasInputContainer">
                <div class="columns is-multiline is-mobile is-centered mb2rem mt1rem">
                    <div class="column is-8-desktop is-11-tablet is-11-mobile">
                        <slot></slot>
                    </div>
                </div>
            </div>
            <div v-else>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Card",
    
    data() {
        return {
        }
    },
    props: {
        hasCardTitle: {
            type: Boolean,
            required: false,
            default: true
        },
        cardTitle: {
            type: String,
            required: false,
            default: ''
        },
        hasInputContainer: {
            type: Boolean,
            required: false,
            default: true
        },
        cardIcon: {
            type: String,
            required: false,
            default: ''
        },
    },
    methods:{

    }
};
</script>


<style scoped>

</style>
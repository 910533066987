<template>

    <div class="container">
      <div class="columns">
        
        <div v-show="PwValidationLog.length > 0" class="ml1rem pb0 mb1rem">
          <ul id="password-constraints" class="ml0rem" v-for="(LogItem, index) in PwValidationLog" v-bind:key="index">
            <li style="font-size:0.9rem;"> {{ LogItem }}</li>
          </ul>
        </div>
        
      </div>            
    </div>   
    
</template>

<script>

export default {
    name: "comp_passwordvalidation",
    
    props: {
      password        : {
        type: String,
        default: '',
      },
      passwordrepeat        : {
        type: String,
        default: '',
      },
    },
    
    data() {
      return {
        PwValidationLog : [],
      }
    },
    
    watch: {
      password: { 
        handler () {
          this.ValidatePW();
        },
      },
      passwordrepeat: { 
        handler () {
          this.ValidatePW();
        },
      },
    },
    
    mounted(){
      this.ValidatePW();
    },
    
    methods: {      
      async ValidatePW(){
        //console.info("validating pw...");
        const pw1          = this.password;
        const pw2          = this.passwordrepeat;
        let ErrLog        = [];
        let has_number    = /\d/.test(pw1);
        let has_lowercase = /[a-z]/.test(pw1);
        let has_uppercase = /[A-Z]/.test(pw1);
        let has_special   = /[!@#$%^&*)(+=._-]/.test(pw1);

        if (pw1 === "" || pw1.length < 8) {
          ErrLog.push(this.$wl('login_passwordval_minchars'));
        } else if (pw1.length > 64) {
          ErrLog.push(this.$wl('login_passwordval_maxchars'));
        }
        if (!has_number) {
          ErrLog.push(this.$wl('login_passwordval_number'));
        }
        if (!has_lowercase) {
          ErrLog.push(this.$wl('login_passwordval_lowercase'));
        }
        if (!has_uppercase) {
          ErrLog.push(this.$wl('login_passwordval_uppercase'));
        }
        if (!has_special) {
          ErrLog.push(this.$wl('login_passwordval_special'));
        }
        if (pw1 !== pw2 || pw2 === "") {
          ErrLog.push(this.$wl('login_passwordval_match'));
        }

        this.PwValidationLog =  ErrLog;
        if (this.PwValidationLog.length > 0) {
          this.$emit('passwordAccepted', false);
        } else {
          this.$emit('passwordAccepted', true);
        }
      },
    }
};
</script>

<style scoped>

ul {
  list-style-type: none;
}
ul li::before {
  content: "\2022"; 
  color: #ff7e82; 
  font-weight: bold;
  display: inline-block; 
  width: 1em; 
  margin-left: -1em; 
}

    
</style>

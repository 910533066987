<template>
  <section class="wa-full-height">

    <div class="columns is-centered is-gapless is-marginless">
      <div class="column is-mobile is-narrow mt2rem mb2rem">
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title"> Name: {{ $route.name }}</div>
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title"> Path: {{ $route.path }}</div>
      </div>
    </div>


    <!-- <Footer></Footer> -->
  </section>
</template>

<script>

export default {
  name: "MainPage",
  
  data() {
    return {
      
    }
  },
  
  mounted(){
    // console.info("mounted:...");
  },
};
</script>

<style scoped>
</style>
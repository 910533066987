// import axios_auth from '@/plugins/axios_auth';
// import router from "@/router/router";

// import {
//     LOGIN_URL,
// } from '@/config/api-url-general';




const state = {
  auth: null
};

const getters = {
  getAuthState: state => {
    return state.auth;
  },
}

const actions = {  
  
};



const mutations = {
  setAuthState: (state, uid) => {
    try {
      
      if (!uid) {
        throw `FB auth data missing:`;
      }
      
      state.auth = {
        uid: uid,
        updated_at: new Date().getTime(),
      }
            
    } catch (err) {
      console.info(`firebase setAuthState: `, err);
      state.auth = {
        uid: null,
        updated_at: new Date().getTime(),
      }
    }
  },
  
};



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
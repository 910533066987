// import Storage from '@/helpers/storage';
// import { COOKIE_ACCESS_TOKEN } from '@/config/app.js';

const ProvideAuthorizationHeader = (request) => {
    // const decryptedToken = Storage.ReadObj(COOKIE_ACCESS_TOKEN, true);
  
    // if (decryptedToken) {
    //     request.headers['Authorization'] = `Bearer ${decryptedToken}`;
    // }

    return request;
};

export { ProvideAuthorizationHeader };
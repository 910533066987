<template>

  <div class="columns is-gapless is-marginless is-centered is-mobile pb1rem">
    <div v-if="confirmLabel && cancelLabel"  class="column is-narrow mr2rem">
      <a @click="CancleBtnClicked" class="button is-rounded is-primary is-outlined has-text-centered" :class="{ buttonsFormDarkmode : isDarkMode, 'is-large' : isLarge }" style="min-width:150px;">
          <span>{{cancelLabel}}</span>
      </a>
    </div>
    <div v-if="confirmLabel && cancelLabel"  class="column"></div>
    <div class="column is-narrow">
      <a @click="ConfirmBtnClicked" class="button is-rounded is-primary has-text-centered"  :class="{ buttonsFormDarkmode : isDarkMode, 'is-large' : isLarge  }" style="min-width:150px;">
          <span>{{confirmLabel}}</span>
      </a>
    </div>
  </div>
    
</template>

<script>
export default {
    name: "ButtonsForm",
    data() {
        return {

        }
    },
    props: {
        confirmLabel: {
            type: String,
            required: true,
            default: ''
        },
        cancelLabel: {
            type: String,
            required: false,
            default: ''
        },
        isDarkMode: {
              type: Boolean,
              required: false,
              default: false,
          },
        isLarge: {
              type: Boolean,
              required: false,
              default: false,
          },

    },
    methods: {
      CancleBtnClicked(){
        this.$emit("CancleBtnClicked");
      },
      ConfirmBtnClicked(){
        this.$emit("ConfirmBtnClicked");
      },
    }
};
</script>



<style>

  .buttonsFormDarkmode{
    background-color:#FFF !important;
    color: #ff7e82  !important;
  }
</style>
import axios_auth from '@/plugins/axios_auth';
// import router from "@/router/router";

// import api_lang from '@/lang/api_language'
// import web_lang from '@/lang/web_language'

import {
  TWOFA_GET_STATUS,
  TWOFA_SETUP,
  TWOFA_VERIFYSETUP,
  TWOFA_REMOVE,
} from '@/config/api-url-general';
        

const state = {
  
};

const getters = {
 
}

const actions = {
  
  async getStatus ({ dispatch }) {    
    try {
      dispatch("loader/showLoader", 15000, { root: true });
      
      let twoFAstatus = await axios_auth.get(TWOFA_GET_STATUS, null);
      // console.info("twoFAstatus:", twoFAstatus);
      
      dispatch("loader/hideLoader", 0, { root: true });
      return twoFAstatus.data.payload;
      
    } catch (err) {
      console.info("Error 2fa getstatus:", err);      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  async setup ({ dispatch }) {    
    try {
      dispatch("loader/showLoader", 15000, { root: true });
      
      let twoFAsetup = await axios_auth.post(TWOFA_SETUP, null);
      // console.info("twoFAsetup:", twoFAsetup);
      
      dispatch("loader/hideLoader", 0, { root: true });
      return twoFAsetup.data.payload;
      
    } catch (err) {
      console.info("Error 2fa getstatus:", err);      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  async verify ({ dispatch }, data) {    
    try {
      dispatch("loader/showLoader", 15000, { root: true });
      
      let twoFAverify = await axios_auth.post(TWOFA_VERIFYSETUP, data);
      // console.info("twoFAverify:", twoFAverify);
      
      dispatch("loader/hideLoader", 0, { root: true });
      return twoFAverify.data.payload;
      
    } catch (err) {
      console.info("Error 2fa verify:", err);      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  async remove({ dispatch }, data) {    
    try {
      dispatch("loader/showLoader", 15000, { root: true });
      
      let twoFAremove = await axios_auth.post(TWOFA_REMOVE, data);
      // console.info("twoFAremove:", twoFAremove);
      
      dispatch("loader/hideLoader", 0, { root: true });
      return twoFAremove.data.payload;
      
    } catch (err) {
      console.info("Error 2fa remove:", err);      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
};



const mutations = {
 
};



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
<template>
  <footer v-if="$route.meta.hideFooter !== true" class="columns is-marginless is-centered has-background-white pb1rem pt2rem" style="background-color:red">
    <div class="column is-10-desktop is-10-mobile is-offset-1-mobile">
      <div class="columns is-mobile is-centered">
        <div class="column is-12">
          <div class="columns is-centered">
            <div>Footer...</div>
            <div class="column is-3-desktop is-12-mobile ta-center p1rem">
              <img
                src="/img/logo.png"
                alt="Logo ProjectX"
                width="100px"
                height="100px"
                style="max-width:200px"
              />
            </div>
            <div class="column is-3-desktop is-12-mobile ta-center p1rem">
              <img
                src="/img/logo.png"
                alt="Logo ProjectX"
                width="100px"
                height="100px"
                style="max-width:200px"
              />
            </div>
            
            
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>





<script>
export default {
  name: "Footer"
};
</script>

<style scoped>
a {
  color: inherit !important;
  text-decoration: none !important;
}

a:hover {
  color: #f37c82 !important;
  text-decoration: none !important;
}
</style>
/* admin testfunction */
export const GET_TEST_DATA = '/admin/testfunction';

/* SERVICE SERVER test */
export const GET_TEST2_DATA = '/testfeed/getdata';
export const GET_TESTJWT_DATA = '/testing/get_jwt_data';









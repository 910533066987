import AES from 'crypto-js/aes';
import encutf8 from 'crypto-js/enc-utf8';
import modecfb from 'crypto-js/mode-cfb';
import padpkcs7 from 'crypto-js/pad-pkcs7';

import { ENCRYPTION_KEY } from '@/config/app.js';
const VueCookies = require('vue-cookies');
const EnableEncryption = process.env.VUE_APP_ENCRYPT_COOKIES == "1";
/*
 * https://www.npmjs.com/package/vue-cookie
 */
const default_cookie_setting = {
  expireTimes: '20min',
  path : null,
  domain : null,
  secure : process.env.VUE_APP_COOKIE_ONLY_SECURE == "1",
  sameSite : 'Lax'
}

export default new class Storage {

  StoreObj(Name, StoreObj, Encrypt = true, Options = null ){
    try {
      let StoreString = JSON.stringify(StoreObj);
      
      if (Encrypt === true) {
        StoreString = this.EncryptString(StoreString, ENCRYPTION_KEY)
      }
      const s =  {...default_cookie_setting, ...Options};
      VueCookies.set(Name, StoreString, s.expireTimes, s.path, s.domain, s.secure, s.sameSite);
      return true;
      
    } catch(err) {
      console.info("Error StoreObj:", err);
      return false;
    }
  }
  
  Delete(Name) {
    try {
      VueCookies.remove(Name);
      return true;
    } catch (err) {
      console.info("Error DeleteAll:", err);
      return false;
    }
  }
  
  DeleteAll() {
    try {
      var c = document.cookie.split("; ");
      for (let i in c) {
        document.cookie =/^[^=]+/.exec(c[i])[0]+"=;expires=Thu, 01 Jan 1970 00:00:00 GMT";    
      }
      return true;
    } catch (err) {
      console.info("Error DeleteAll:", err);
      return false;
    }
  }
  
  
  ReadObj(Name, Decrypt = true, failRtnValue = null){
    try {
      let Read = VueCookies.get(Name);
      if (typeof(Read) !== "string" || Read === "") {
        return failRtnValue;
      }
      
      if (Decrypt) {
        Read = this.DecryptString(Read, ENCRYPTION_KEY);
      }
      
      return JSON.parse(Read);
      
    } catch(err) {
      console.info("Error ReadObj", Name);
      return failRtnValue;
    }
  }
  
  EncryptString(ThaString, ENCRYPTION_KEY){
    if (!EnableEncryption) return ThaString;
    
    return AES.encrypt(ThaString, ENCRYPTION_KEY, {
      keySize: 128 / 8,
      iv: ENCRYPTION_KEY,
      mode: modecfb,
      padding: padpkcs7,
    }).toString();
  }
  
  DecryptString(ThaString, ENCRYPTION_KEY){
    if (!EnableEncryption) return ThaString;
    
    return encutf8.stringify(AES.decrypt(ThaString, ENCRYPTION_KEY, {
      keySize: 128 / 8,
      iv: ENCRYPTION_KEY,
      mode: modecfb,
      padding: padpkcs7,
    }));
  }
  
};

<template>
  <div class="columns is-marginless">

    <div v-if="isCentered" class="column is-12">    
        <div class="columns is-gapless is-marginless is-centered is-mobile">
          <div class="column is-narrow is-mobile">
            <div class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >{{ title }}</div>
          </div>
        </div>
    </div>

    <div v-else-if="isCenteredText" class="column is-12">    
        <div class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined ta-center" >{{ title }}</div>
    </div>


    <div v-else class="column is-6-desktop is-8-tablet is-12-mobile">    
        <div class="columns is-gapless is-marginless">
          <div class="column is-mobile is-narrow">
            <div class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >{{ title }}</div>
          </div>
        </div>
    </div>

  </div>
</template>

<script>
export default {
    name: "Title",
    data() {
        return {
        }   
    },    
    props: {
        title: {
            type: String,
            required: true,
            default: 'Default'
        },
        isCentered: {
            type: Boolean,
            required: false,
            default: false
        },     
        isCenteredText: {
            type: Boolean,
            required: false,
            default: false
        },                      
    },
};
</script>

<style>

</style>
<template>
  <section class="wa-full-height">
    
    <div class="columns is-6-tablet is-5-desktop is-5-widescreen is-4-fullhd">
      <div class="column has-text-centered mt2rem">
        <router-link :to="{ name: 'Home' }">
          <figure class="image is-128x128 is-inline-block">
            <img class="login-logo" src="/img/logo.png" alt="Logo">
          </figure>
        </router-link>
      </div>        
    </div>
      

    <form action="" method="get" autocomplete="off" v-if="isLoaded" class="columns is-centered is-gapless is-marginless">
      
      <div class="column is-mobile is-narrow mb2rem">
        <div class="underline is-size-3 mb2rem ta-center c484848">
           {{ $wl('password_reset_title') }}
        </div>
        
        <div class="columns is-multiline is-marginless">
          <div class="is-size-6 mb2rem ta-center c484848">
            {{ $wl('password_reset_subtitle') }}
          </div>
        </div>
        
        <div class="columns animated preFadeInLeft fadeInLeft">
          <b-field class="column" :label="$wl('password_reset_password')" label-for="password">
            <b-input type="password" v-model="frmdata.password" rounded value="" required
              class="is-fullwidth"
              autocomplete="new-password" id="password" name="password" 
              password-reveal maxlength="100" aria-describedby="password-constraints">
            </b-input>
          </b-field>
        </div>
        
        <div class="columns animated preFadeInLeft fadeInLeft">
          <b-field class="column" :label="$wl('password_reset_password_rep')" label-for="password-repeat">
            <b-input type="password" v-model="frmdata.password_repeat" rounded value="" required
              autocomplete="new-password" id="password-repeat" name="password-repeat" 
              password-reveal maxlength="100"  aria-describedby="password-constraints">
            </b-input>
          </b-field>
        </div>
          
        <div class="columns animated preFadeInLeft fadeInLeft">
        <passwordValidation 
            class="mt10"
            :password="frmdata.password" 
            :passwordrepeat="frmdata.password_repeat"
            @passwordAccepted="SetAccepted" 
          />
        </div>
        
        <div class="columns animated preFadeInLeft fadeInLeft">
          <b-button @click="SubmitPasswordReset()"  
              class="button is-fullwidth is-primary has-text-centered is-medium is-rounded" 
              style="justify-content:center; padding: 22px 40px; margin-top:30px;"
            >
            {{ $wl('login_reset_pw_submit') }}
          </b-button>
        </div>
          
        
      </div>
    </form>
    
    


    <!-- <Footer></Footer> -->
  </section>
</template>

<script>

import passwordValidation from "@/components/public/general/password_validation.vue";

export default {
  name: "passwordreset",
  components: {
    passwordValidation
  },
  
  data() {
    return {
      isLoaded : false,
      frmdata: {
        uid         : null,
        resettoken  : null,
        password    : '',
        password_repeat    : '',
      },
      password_valid   : false,
    }
  },
  
  mounted(){
    this.frmdata.uid = this.$route.query.uid;
    this.frmdata.resettoken = this.$route.query.v;
    
    this.ValidateResetPWToken();
  },
  
  methods: {
    async ValidateResetPWToken(){
      let validateRtn = await this.$store.dispatch('authentication/validatePasswordResetToken', this.frmdata);
      // console.info("validateRtn:", validateRtn);
      if (validateRtn !== true) {
        this.$router.push({ name: 'Login', params: { pageViewMode  : 'pw_reset', }, })
                .catch(err => { console.info('router error:', err) });
      }
      this.isLoaded = true;
    },
    
    SetAccepted(Accepted){
      this.password_valid = Accepted;        
    },
    
    async SubmitPasswordReset(){
      if (!this.password_valid) {
        return this.$store.commit("dialog/setDialog", 
          { DialogType: 'alert', 
            title: this.$wl('login_error_alert_title'), 
            message: this.$wl('password_reset_invalid_password'),
          }
        );  
      }
      
      let changeRtn = await this.$store.dispatch('authentication/changePassword', this.frmdata);    
      // console.info("SubmitPasswordReset:",changeRtn);
      if (changeRtn !== false) {
          this.$store.commit("dialog/setDialog", 
            { DialogType: 'alert', 
              title: this.$wl('password_reset_success_title'), 
              message: changeRtn.m,
            }
          ); 
        
          this.$router.push({ name: 'Login', params: { pageViewMode  : 'login', }, })
                .catch(err => { console.info('router error:', err) });
      }
    },
  }
  
};
</script>

<style scoped>
.login-logo{
  height: 100%;
  width: auto;
}
</style>
import Vue                    from "vue";
import Router                 from "vue-router";
Vue.use(Router);

import store                  from "@/store";

import Storage                from '@/helpers/storage';
import { COOKIE_LOGIN_REDIRECT } from '@/config/app.js';

// import LocalStorageEncrypted  from "./cache/_localstorage_encrypted";

import Routes                 from "@/router/routes/index.js"

const router = new Router({
  mode: "history",
  base: '/',
  routes: Routes
});


router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  // console.info("to:", to);
  const ToMinAccLevel     = to.meta.MinAccLevel || 0;
  // console.info("ToMinAccLevel:", ToMinAccLevel);
  
  let UserAccessLevel = 0;  
  let loggedInState = store.state.authentication.loggedInState;  
  if (loggedInState.updateTime === null) {
    await store.dispatch('authentication/updateLoginState', null);
    loggedInState = store.state.authentication.loggedInState;
  }
  
  if (loggedInState.isLoggedIn !== null && loggedInState.accessLevel > 0 ) {
    UserAccessLevel = loggedInState.accessLevel;
  }

  // console.info("Router ToMinAccLevel:", ToMinAccLevel); console.info("Router UserAccessLevel:", UserAccessLevel);
  if (UserAccessLevel < ToMinAccLevel) {
    console.info("router, no access goto LoginPage...");
    //console.info("gotoLoginPage:", ToMinAccLevel); console.info("gotoLoginPage:", UserAccessLevel);
    //Storage.Set_UnEncData("LoginRedirect", to.path, 240);
    Storage.StoreObj(COOKIE_LOGIN_REDIRECT, to.fullPath, true);
    return next( '/login' );
  }

  next();
});

export default router;
<template>
  <div id="app" class="is-fullheight-with-navbar">
    <navBar></navBar>
    
    <router-view :key="$route.fullPath" />
    <myFooter></myFooter>
    
    <myDialog></myDialog>
    <myLoader></myLoader>
    <firebaseAuth></firebaseAuth>
        
  </div>
</template>

<script>
import navBar       from "@/components/navBar";
import myFooter     from "@/components/public/general/footer.vue";
import myLoader     from "@/components/public/general/loader.vue";
import myDialog     from "@/components/public/general/dialogs.vue";
import firebaseAuth from "@/components/public/general/firebase_auth.vue";


export default {
  name: "app",
  
  components: {
    navBar,
    myFooter,    
    myLoader,    
    myDialog,
    firebaseAuth,
  },
  
  data () {
    return {
      // publicPath: process.env.VUE_APP_WEB_ROOT
    }
  },
  
  created(){},
  
  mounted(){
    this.$store.dispatch('authentication/updateLoginState', null);
  },
  
  methods: {
    
  }

};
</script>


<style lang="css">
  html {
    height: 100vh;
  }

  body {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    background-color: #f4f6fb;
    font-family: 'Roboto';
  }


  @font-face {
    font-family: "Roboto";
    src: url("/fonts/Roboto-Regular.eot");
    src: url("/fonts/Roboto-Regular.eot?#iefix") format('embedded-opentype'),
        url("/fonts/Roboto-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  /* Roboto italic */

  @font-face {
    font-family: "Roboto";
    src: url('/fonts/Roboto-Italic.eot');
    src: url('/fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Roboto-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }

  /* Roboto bold */

  @font-face {
    font-family: "Roboto";
    src: url('/fonts/Roboto-Bold.eot');
    src: url('/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  #app {
    margin: 0;
    padding: 0;
  }
</style>

<template>
  <b-navbar v-if="$route.meta.navBar === true" class="has-background-white" close-on-click>
    
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img class="login-logo" src="/img/logo.png" alt="Logo" height="56" width="56">
      </b-navbar-item>
    </template>
    
    <template slot="start">      
      <b-navbar-item tag="router-link" :to="{ name: 'Home' }">Home</b-navbar-item>
      
      <b-navbar-dropdown v-if="!isSignedIn" label="Over ons" class="is-hoverable">
        <b-navbar-item tag="router-link" :to="{ name: 'Aboutus' }">Aboutus</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'Privacy' }">Privacy statement</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'TermsAndConditions' }">Terms and conditions</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'Disclaimer' }">Disclaimer</b-navbar-item>
      </b-navbar-dropdown>      
      
      <b-navbar-dropdown v-if="isSignedIn" label="Admin" class="is-hoverable">
        <b-navbar-item tag="router-link" :to="{ name: 'Aboutus' }">Users</b-navbar-item>
      </b-navbar-dropdown>
      
      <b-navbar-dropdown v-if="isSignedIn" label="Example" class="is-hoverable">
        <b-navbar-item tag="router-link" :to="{ name: 'FlexBoxExample' }">FlexBox</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'GridExample' }">Grid1</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'GridExample2' }">Grid2</b-navbar-item>
      </b-navbar-dropdown>
      
      <!-- <b-navbar-item v-if="isSignedIn" tag="router-link" :to="{ name: 'profile' }">Profile</b-navbar-item> -->
      
      <b-navbar-item v-if="isSignedIn && accessLevel >=10" tag="router-link" :to="{ name: 'userpage'}" >test userpage</b-navbar-item>
      
      <b-navbar-item tag="router-link" :to="{ name: 'Contact' }">Contact</b-navbar-item>
    </template>

    <template slot="end">      
      <b-navbar-item v-if="isSignedIn" tag="div">        
        <div class="buttons">  
          <router-link class="button is-light" :to="{ name: 'profile'}" >Account</router-link>
          
          <b-button class="button is-primary" @click="Logout">
            <strong>Logout</strong>
          </b-button>
        </div>
      </b-navbar-item>
      
      <b-navbar-item v-if="!isSignedIn" tag="div">        
        <div class="buttons"> 
          <b-button v-if="register_allowed" class="button is-primary" @click="GotoRegistreren">
            <strong>Registreren</strong>
          </b-button>
          
          <router-link class="button is-light" :to="{ name: 'Login'}">Inloggen</router-link>          
        </div>
      </b-navbar-item>
    </template>
    
  </b-navbar>
</template>

<script>

import { mapState } from 'vuex';
// import AccountMenu          from "@/components/accountmenu.vue";

export default {
  name: "Nav",
  
  data() {
    return {
      register_allowed: false,
      isSignedIn    : false,
      accessLevel   : 0,
      
      FirebaseUID   : null,
    };
  },
  
  computed: {
    ...mapState('authentication', ['authentication/loggedInState','loggedInState']),
  },
  watch: {
    loggedInState(newLoggedInState) {
      // console.info("LoggedInState Changed:",  newLoggedInState);
      this.UpdateLoginAccess(newLoggedInState);
    },
  },
  
  created() {},
  
  async mounted() {
    this.register_allowed = process.env.VUE_APP_REGISTER_ALLOWED == "1"
    this.$store.dispatch('authentication/updateLoginState', null);
  },
  
  
  methods: {
    
    UpdateLoginAccess(loggedInState){
      let accessLevel = parseInt(loggedInState.accessLevel);
      if (parseInt(accessLevel) > 0) {
        this.isSignedIn = true;
        this.accessLevel = accessLevel
        return;
      }
      
      this.isSignedIn = false;
      this.accessLevel = 0;
      return;
    },
    
    async Logout(){
      await this.$store.dispatch('authentication/logout', null);
      this.$router.push({path: '/'});
    },
    
    GotoRegistreren(){
      this.$router.push({ name: 'register', params: { pageViewMode  : 'register', }, })
                  .catch(err => { console.info('router error:', err) });
    }
  }
};
</script>

<style scoped>

  .login-logo{
    height: 100%;
    width: auto;
  }
  .navbar-item img {
    max-height: 3.5rem;
  }

  .wa-navigation-user {
    max-height: 3.5rem;
    box-shadow: 0 2px 0 0 #f5f5f5;
    border-bottom: 1px solid #e5e5e5;
  }

  .button.is-light.HAweb{
    background-color: #7AB41D;
    color: #ffffff;
    border-width:3;
    border-color :#7AB41D;
  }

</style>
<template>
  <section class="wa-full-height">

    <div class="columns is-centered is-gapless is-marginless mt2rem mb1rem is-centered">
      <div class="column is-6-desktop is-8-tablet is-12-mobile">
        <h1>TEST</h1>
        <b-button @click="sendcookietest()">
          sendcookies?
        </b-button>
        <b-button @click="sendRefreshToken()">
          sendRefreshToken?
        </b-button>
      </div>
    </div>

    <!-- <Footer></Footer> -->
  </section>
</template>

<script>

// import axios from 'axios';
import axios from '@/plugins/axios_api';
// import axios from '@/plugins/axios_auth';

// import Storage from '@/helpers/storage';
// import { COOKIE_REFRESH_TOKEN } from '@/config/app.js';
import store from '@/store';

export default {
  name: "Test",

    
  data() {
    return {
      
    }
  },
  
  
  mounted(){
    
  },
  
  methods: {
            
    async sendcookietest(){
      console.info("done!");
      let rtn = await axios.post('http://localhost:12345/api/v1/testing/testcookies');
      console.info("rtn:", rtn);
      
      //let refreshToken = Storage.ReadObj(COOKIE_REFRESH_TOKEN, true);
      //console.info("refreshToken:", refreshToken);
      
      //let testCookie = Storage.ReadObj('NEWcokkieName2', true);
      //console.info("testCookie:", testCookie);
    },
    
    async sendRefreshToken(){
     let refreshRtn = await store.dispatch('authentication/refreshToken', null, { root: true });
     console.info("refreshRtn:", refreshRtn);
    },
    
  },
};
</script>

<style scoped>
</style>
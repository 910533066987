<template>
  <section>
      
  </section>
</template>

<script>

import { mapState } from 'vuex';
import { FBsignInAnonymously, FBonAuthStateChanged  }    from "@/plugins/firebase/auth";
// import { FBgetData, FBmonitorData, FBRemoveMonitorData, FBsetData, FBaddData, FBRemoveData }       from '@/plugins/firebase/db';

/*
 * Sign into Firebase Anonymously and get firebase UID
*/

export default {
    name: "firebase_auth",
    
    data() {
      return {
        FBuid: '',
      }
    },
    
    computed: {
    ...mapState('firebase', ['firebase/auth','auth']),
    },
    watch: {
      auth(newAuthState) {
        this.FBuid = newAuthState.uid;
        this.getFBuid();
      },
    },
    
    
    mounted(){    
      FBonAuthStateChanged(this.FBStateChanged)
      FBsignInAnonymously();
    },
    
    methods: {
      async getFBuid(){
        let FBauthState = this.$store.getters['firebase/getAuthState'];
        if (!FBauthState) {
          this.FBuid = null
          return
        }
        this.FBuid = FBauthState.uid;
        // console.info(`---->>firebase:`, await FBgetData(`/Chime/d3dc9c61-1ed5-4557-a18a-2674be951f49/users/M7PRIwSFxbUxJ6lxVlfJmWuLZxg2`))
        // await FBmonitorData(`/Chime/d3dc9c61-1ed5-4557-a18a-2674be951f49/info`, this.FBMonitorCallback1)
        // await FBRemoveMonitorData(`/Chime/d3dc9c61-1ed5-4557-a18a-2674be951f49/info`)
        // await FBmonitorData(`/Chime/d3dc9c61-1ed5-4557-a18a-2674be951f49/users`, this.FBMonitorCallback2)
        // await FBsetData(`/Chime/d3dc9c61-1ed5-4557-a18a-2674be951f49/test`, {top: "ASDFASDF"})
        // await FBaddData(`/Chime/d3dc9c61-1ed5-4557-a18a-2674be951f49/test`, {topnew: "newdata??"})
        // await FBRemoveData(`/Chime/d3dc9c61-1ed5-4557-a18a-2674be951f49/test/top`)
      },
      
      FBStateChanged(Auth, user){
        if (!user) {
          console.info(`FIREBASE SIGNED OUT...`);
          this.$store.commit('firebase/setAuthState', null);
          return;
        }
        console.info(`Firebase:`, user.uid);
        this.$store.commit('firebase/setAuthState', user.uid);
      }
    }
};
</script>

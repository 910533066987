import axios from 'axios';
import { API_ROOT } from '@/config/app.js';
import { ProvideAuthorizationHeader } from '@/plugins/interceptors/RequestInterceptor';
import { ResponseErrorHandler } from '@/plugins/interceptors/ResponseInterceptor';

const instance = axios.create({
    baseURL : API_ROOT,
    method  : 'POST',
    timeout : 60000,
    json    : true,
});

instance.interceptors.request.use(
    request => ProvideAuthorizationHeader(request),
    error => Promise.reject(error),
);

instance.interceptors.response.use(
    response => response,
    error => ResponseErrorHandler(error),
);

export default instance;